<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex" *ngIf="item$ | async as i">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' |
          translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' |
          translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
    </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" *ngIf="pageAccessMode == 3">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{ 'BUYER_TYPE_BC' | translate
          }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link"> {{ 'CONFIRM_ORG_DETAILS' | translate }}</span>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">
    Confirm that this is your organisation
  </h1>
  <div>
    <div class="govuk-form-group" *ngIf="isInvalid">
      <div class="govuk-error-summary govuk-grid-column-three-quarters" aria-labelledby="error-summary-title"
        role="alert" tabindex="-1" data-module="govuk-error-summary">
        <h2 class="govuk-error-summary__title" id="error-summary-title"> There is a problem</h2>
        <div class="govuk-error-summary__body">
          <ul class="govuk-list govuk-error-summary__list">
            <a (click)="setFocus()" *ngIf="isInvalid">
              <li>Select your Organisation’s Country</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <div class="line-to-grow-with-content">
      <p>
        <strong style="font-weight: 500">{{ i?.name }}</strong>
      </p>
      <hr class="full-length" />

      <p>
        {{ i?.address?.streetAddress }}<br *ngIf="i?.address?.streetAddress" />
        {{ i?.address?.locality }}<br *ngIf="i?.address?.locality" />
        {{ i?.address?.region }}<br *ngIf="i?.address?.region" />
        {{ i?.address?.postalCode }}<br *ngIf="i?.address?.postalCode" />
        {{ i?.address?.countryName }}<br *ngIf="i?.address?.countryName" />
      </p>
      <div class="govuk-form-group" id="country-code-form-group" [class.govuk-form-group--error]="isInvalid == true">
        <span class="govuk-label" for="country-code">
          {{ "Country" | translate }}
        </span>
        <span class="govuk-error-message" *ngIf="isInvalid">
          <span class="govuk-visually-hidden">Error:</span>Please select your Organisation’s Country
        </span>
        <mat-form-field class="form-field-country form-field-required" [class.govuk-input--error]="isInvalid">
          <mat-select [(ngModel)]="countryCode" #singleSelect panelClass="mat-country-select-site" id="country-code"
            (selectionChange)="onChangecountry(countryCode)">
            <mat-option>
              <ngx-mat-select-search [formControl]="countryCodeCtrl" placeholderLabel="Search"
                noEntriesFoundLabel="No matching country found">
                <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!countryCode" value="">Select Country</mat-option>
            <mat-option *ngFor="let topCountry of topCountries" [value]="topCountry.countryCode">
              {{ topCountry.countryName }} - {{ topCountry.countryCode }}
            </mat-option>
            <mat-option *ngIf="topCountries.length > 0" disabled="true">────────────────────</mat-option>
            <mat-option *ngFor="let country of filteredCountryDetails | async" [value]="country.countryCode">
              {{ country.countryName }} - {{ country.countryCode }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <hr class="full-length" />

      <p>
        <strong style="font-weight: 600">{{ schemeName }} registration number</strong><br />{{ i?.identifier?.id }}
      </p>
      <hr class="full-length" />

      <div class="govuk-form-group">
        <fieldset class="govuk-fieldset">

          <div class="govuk-radios">
            <div class="govuk-radios__item" id="correctDetailsRadioSelection">
              <input class="govuk-radios__input" type="radio" name="orgGroup" checked="checked"
                value="manage-org/register/user" [(ngModel)]="orgGroup" id="correctDetailsRadioButton" />
              <label class="govuk-label govuk-radios__label" for="correctDetailsRadioButton">
                Yes, these details are correct
              </label>
            </div>
            <div class="govuk-radios__item" id="wrongAddressRadioSelection">
              <input class="govuk-radios__input" type="radio" name="orgGroup"
                value="manage-org/register/error/wrong-details" [(ngModel)]="orgGroup" id="wrongAddressRadioButton" />
              <label class="govuk-label govuk-radios__label" for="wrongAddressRadioButton">
                Yes, but the address is wrong
              </label>
            </div>
            <div class="govuk-radios__item" id="notMyOrgRadioSelection">
              <input class="govuk-radios__input" type="radio" name="orgGroup" (change)="OnOrgDetailsConfirmationChange($event)"
                value="manage-org/register/error/not-my-details" [(ngModel)]="orgGroup" id="notMyOrgRadioButton" />
              <label class="govuk-label govuk-radios__label" for="notMyOrgRadioButton">
                No, this isn't my organisation
              </label>
            </div>
          </div>
        </fieldset>
      </div>

      <button type="button" id="continueButton" class="govuk-button" data-module="govuk-button" (click)="onSubmit('Continue')">
        Continue
      </button>
    </div>
  </div>
</div>