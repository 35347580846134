<div class="admin_table" *ngIf="isAdmin">
    <table class="govuk-table" *ngIf="data.length > 0; else noData">
        <thead class="govuk-table__head">
            <tr class="govuk-table__row">
                <h2 scope="col" class="govuk-table__header border-bottom-0 govuk-heading-s">{{headerText}}</h2>
                <th scope="col" class="govuk-table__header border-bottom-0 govuk-text-align-right">
                    <p *ngIf="accessTable === 'groupsMember'" class="govuk-body"><a href="javascript:;"
                            class="govuk-link" (click)="onTopToggle()">{{groupShow ==
                            true ? 'hide all' : (groupShow == false ? 'show all' : '')}}
                            ({{data.length}})</a></p>
                    <p *ngIf="accessTable === 'noneGroupsMember'" class="govuk-body"><a href="javascript:;"
                            class="govuk-link" (click)="onTopToggle()">{{groupShow ==
                            true ? 'hide all' : (groupShow == false ? 'show all' : '')}}
                            ({{data.length}})</a></p>
                </th>
            </tr>
        </thead>
        <tbody class="govuk-table__body border-top-1" *ngIf="groupShow">
            <tr class="govuk-table__row" *ngFor="let groupdata of data ; let i=index">
                <th scope="row" class="govuk-table__header">
                    <div class="govuk-checkboxes" data-module="govuk-checkboxes">
                        <div class="govuk-checkboxes__item">
                            <span>
                                <input class="govuk-checkboxes__input" id="{{'orgGroupControl_' + groupdata.groupId}}"
                                    name="{{'orgGroupControl_' + groupdata.groupId}}" type="checkbox" value="true"
                                    [(ngModel)]="groupdata.checked"
                                    (click)="onCheckBoxClick(groupdata,groupdata.checked)"
                                    [attr.disabled]="groupdata.disabled">
                                <label
                                    class="govuk-label govuk-checkboxes__label govuk-!-margin-bottom-2 govuk-!-margin-left-4"
                                    for="{{'orgGroupControl_' + groupdata.groupId}}">
                                    <span>
                                        <a [routerLink]="['/manage-groups/view']" [queryParams]="{ data: getQueryData(groupdata.groupId) }" class="govuk-link" 
                                        href="javascript:void(0)" target="_blank">{{groupdata[headerTextKeys]}}</a>
                                        <span *ngIf="groupdata.serviceRoleGroups.length > 0; else noRoleData">
                                            <ul class="govuk-list govuk-list--bullet govuk-!-padding-top-4 govuk-!-padding-left-3"
                                                id="{{groupdata.groupId}}" style="display: none;">
                                                <li *ngFor="let orgRole of groupdata.serviceRoleGroups">{{orgRole.name}}
                                                    <strong *ngIf="orgRole.approvalStatus === 0"> - Pending
                                                        approval</strong>
                                                </li>
                                            </ul>
                                        </span>
                                        <ng-template #noRoleData>
                                            <ul class="govuk-list govuk-list--bullet govuk-!-padding-top-4 govuk-!-padding-left-3"
                                                id="{{groupdata.groupId}}" style="display: none;">
                                                <li>
                                                    <label class="no-data-message no-data-message-l"> {{noRoleText}}
                                                    </label>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </span>
                                </label>
                            </span>




                        </div>
                    </div>
                </th>
                <td class="govuk-table__cell govuk-text-align-right">
                    <a *ngIf="groupdata.groupType === 0" href="javascript:;" class="govuk-link"
                        (click)="onBottomToggle($event, groupdata)">{{getElementStatus(groupdata.groupId)}}</a>
                </td>
            </tr>
        </tbody>
    </table>
    <ng-template #noData>
        <div>
            <fieldset class="gov_uk_fieldset">
                <h2 class="custom_strong">{{headerText}}</h2>
                <br>
                <span *ngIf="accessTable === 'groupsMember'" class="none_span">
                    {{!noDataGroupsMemberMessage? 'This user is not
                    member of any group.':noDataGroupsMemberMessage}}</span>
                <span *ngIf="accessTable === 'noneGroupsMember'" class="none_span">
                    {{!noDatanoneGroupsMemberMessage? 'There are no unassigned groups for
                    this user.':noDatanoneGroupsMemberMessage}}



                </span>
            </fieldset>
        </div>
    </ng-template>
</div>
<div class="admin_table" *ngIf="!isAdmin">
    <table class="govuk-table" *ngIf="data.length > 0; else noDataIsNotAdmin">
        <thead class="govuk-table__head">
            <tr class="govuk-table__row">
                <th scope="col" class="govuk-table__header">{{headerText}}</th>
                <th scope="col" class="govuk-table__header"></th>
            </tr>
        </thead>
        <tbody class="govuk-table__body">
            <tr class="govuk-table__row" *ngFor="let groupdata of data ; let i=index">
                <th scope="row" class="govuk-table__header">{{groupdata[headerTextKeys]}}
                    <ul class="govuk-list govuk-list--bullet govuk-!-margin-top-4" id="{{groupdata.groupId}}"
                        style="display: none;">
                        <span *ngIf="groupdata.serviceRoleGroups.length > 0; else noRoleDataIsNotAdmin">
                            <li *ngFor="let orgRole of groupdata.serviceRoleGroups">{{orgRole.name}}
                                <strong *ngIf="orgRole.approvalStatus === 0"> - Pending approval</strong>
                            </li>
                        </span>
                        <ng-template #noRoleDataIsNotAdmin>
                            <label class="no-data-message">{{noRoleText}}</label>
                        </ng-template>
                    </ul>
                </th>
                <td class="govuk-table__cell govuk-text-align-right"><a href="javascript:;" class="govuk-link"
                        (click)="toggleRoleForUser(groupdata.groupId)">{{getElementStatus(groupdata.groupId)}}</a></td>
            </tr>
        </tbody>
    </table>
    <ng-template #noDataIsNotAdmin>
        <div>
            <fieldset class="gov_uk_fieldset">
                <strong class="custom_strong">{{headerText}}</strong>
                <br>
                <br>
                <span *ngIf="accessTable === 'groupsMember'" class="none_span">
                    {{!noDataGroupsMemberMessage? 'This user is not
                    member of any group.':noDataGroupsMemberMessage}}</span>
            </fieldset>
        </div>
    </ng-template>
</div>