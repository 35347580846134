<!-- <div class="content flex" [@slide]="(sideNavVisible$ | async) ? 'close' : 'open'"> -->
<div class="user-profile-container">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link">Manage your organisation</span>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">Manage your organisation</h1>
  <div class="govuk-error-summary user-profile-form-error" aria-labelledby="error-summary-title" role="alert"
    tabindex="-1" id="error-summary" data-module="govuk-error-summary" *ngIf="!isValid && submitted">
    <h2 class="govuk-error-summary__title" id="error-summary-title">
      {{ 'ERROR_SUMMARY' | translate }}
    </h2>
    <div class="govuk-error-summary__body">
      <ul class="govuk-list govuk-error-summary__list">
        <li *ngIf="submitted && !isValid">
          <a href="javascript:;" onClick="return false;" (click)="setFocus()">
            {{'ERROR_AT_LEAST_ONE_SIGN_IN_PROVIDER' | translate }} </a>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <p style="margin-top:40px;">
      <strong style="font-weight: 600;font-size: 24px;">{{ org.identifier?.legalName }}</strong><br />
      {{ org.address?.streetAddress }}<br *ngIf="org.address?.streetAddress" />
      {{ org.address?.locality }}<br *ngIf="org.address?.locality" />
      {{ org.address?.region }}<br *ngIf="org.address?.region" />
      {{ org.address?.postalCode }}<br *ngIf="org.address?.postalCode" />
      {{ org.address?.countryName }}<br *ngIf="org.address?.countryName" />
    </p>
  </div>
  <h2 class="govuk-heading-s"> {{ 'BUYER_SUPPLIER_TYPE' | translate }}</h2>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
      <div>
        <p *ngIf="org.detail?.supplierBuyerType == 0">{{ 'SUPPLIER_TYPE' | translate }}</p>
        <p *ngIf="org.detail?.supplierBuyerType == 1">{{ 'BUYER_TYPE' | translate }}</p>
        <p *ngIf="org.detail?.supplierBuyerType == 2">{{ 'BUYER_AND_SUPPLIER_TYPE' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="govuk-form-group" *ngIf="idps" [class.govuk-form-group--error]="!isValid && submitted">
    <fieldset class="govuk-fieldset">
      <h2 class="govuk-heading-s">
        {{ 'SIGN_IN_PROVIDER'| translate }}
      </h2>
      <div id="signin-hint" class="govuk-hint" style="color: black;">
        {{ idpStatus == false ? 'A sign in provider gives users the option to use a different service to sign in. Sign in providers are set at an
        organisational level. Any updates applied here will apply to all of your organisation’s users' : (idpStatus == true ? 'Users can only use a User ID and password to sign in. You may be able to allow additional sign-in methods in the future.' : '')}}
      </div>
      <span *ngIf="!isValid && submitted" id="group-error" class="govuk-error-message label-detail-text-gap">
        <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
        {{'ERROR_AT_LEAST_ONE_SIGN_IN_PROVIDER' | translate }}
      </span>
      <div class="govuk-checkboxes label-detail-text-gap">
        <div class="govuk-checkboxes__item" *ngFor="let idp of idps">
          <input type="checkbox" [value]="idp.id" #input [checked]="idp.enabled === true"
            [attr.disabled]="idpStatus === true ? true : null"
            (change)="onIdentityProviderChange($event,idp)" class="govuk-checkboxes__input"
            id="orgRoleControl_{{idp.id}}">
          <label class="govuk-label govuk-checkboxes__label" for="orgRoleControl_{{idp.id}}">{{idp.name}}</label>
        </div>
      </div>
    </fieldset>
  </div>

  <div class = "govuk-form-group">
    <fieldset class="govuk-fieldset">
        <h2 class="govuk-heading-s">
        {{'TWO_FACTOR_AUTHENTICATION_ORG_REGISTERATION' | translate}}</h2>
        <p class="govuk-hint" style="color: black;">{{'MFA_HELP_TEXT' | translate}}</p>
        <div>
          <details class="govuk-details" data-module="govuk-details">
            <summary class="govuk-details__summary">
                <span class="govuk-details__summary-text">
                    {{'WHAT_IS_2FA' | translate}}
                </span>
            </summary>
            <div class="govuk-details__text">
                <p>
                  {{'2FA_HELPTEXT' | translate}}
                </p>
            </div>
        </details>
      </div>
          <div class="govuk-radios"> 
            <div class="govuk-radios__item" id="requiredRadioSelection">
              <input class="govuk-radios__input" type="radio" name="mfa_required" id="mfa_required" value="required" (change)="onRadioChange()" [(ngModel)]="selectedOption">
              <label class="govuk-label govuk-radios__label" for="mfa_required">
                <strong> {{'REQUIRED' | translate}} </strong> </label>
                <div class="govuk-hint govuk-radios__hint">
                {{'REQUIRED_INFO' | translate}}
            </div>
            </div>
             
            <div class="govuk-radios__item" id="optionalRadioSelection">
              <input class="govuk-radios__input" type="radio" name="mfa_optional" value="optional" id="mfa_optional"  (change)="onRadioChange()"[(ngModel)]="selectedOption">
              <label class="govuk-label govuk-radios__label" for="mfa_optional">
               <strong>{{'OPTIONAL' | translate}} </strong>  </label>
               <div class="govuk-hint govuk-radios__hint">
             {{'OPTIONAL_INFO' | translate}}
              </div>
            </div>
          </div>
    </fieldset>
  </div>
  <div id = "contact-details">
    <h2 class="govuk-heading-s"> {{ 'CONTACT_DETAILS' | translate }}</h2>
    <app-details [detailsData]="detailsData[0]"></app-details>
  </div>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
      <div>
        <app-govuk-table [headerTextKeys]="contactTableHeaders" [data]="contactData"
          [dataKeys]="contactColumnsToDisplay" [isHyperLinkVisible]="true" [isHyperLinkRowVisible]="isHyperLinkRowVisible" [hyperLinkText]="'EDIT'"
          (hyperLinkClickEvent)="onContactEditClick($event)" [useClientPagination]="true">
        </app-govuk-table>
      </div>
    </div>
  </div>
  <div class="govuk-form-group govuk-button-group">
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
    *ngIf="contactAddAnother" (click)="onContactAddClick('Add another contact')">{{
      'ADD_ANOTHER_CONTACT_BTN' | translate }}</button>
      <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
    *ngIf="!contactAddAnother" (click)="onContactAddClick('Add contact')">{{
      'ADD_CONTACT' | translate }}</button>
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
      (click)="onContactAssignClick('Find and assign contact')">
      {{ 'FIND_AND_ASSIGN_CONTACT_BTN' | translate }}</button>
  </div>
  <div id ="site-details">
    <h2 class="govuk-heading-s">{{ 'SITE_DETAILS' | translate }}</h2>
    <app-details [detailsData]="detailsData[1]"></app-details>
  </div>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
      <div>
        <app-govuk-table [headerTextKeys]="siteTableHeaders" [data]="siteData" [dataKeys]="siteColumnsToDisplay"
          [isHyperLinkVisible]="true" [hyperLinkText]="'EDIT'" (hyperLinkClickEvent)="onSiteEditClick($event)"
          [useClientPagination]="true">
        </app-govuk-table>
      </div>
    </div>
  </div>
  <div class="govuk-form-group govuk-button-group">
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
      (click)="onSiteAddClick('Add a new site')">Add a new site</button>
  </div>
  <div id ="registry-details">
  <h2 class="govuk-heading-s" *ngIf="registries">Registries</h2>
  <app-details [detailsData]="detailsData[2]"></app-details>
  <h2 class="govuk-heading-s">Below are the registries currently linked to your
    organisation</h2>
  </div> 
 <app-registry-govuk-table  [pageName]="'MO'" (hyperLinkClickEvent)="generateRegistryRemoveRoute($event)" ></app-registry-govuk-table>

  <div class="govuk-form-group govuk-button-group" *ngIf="registries != null">
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
      (click)="onRegistryAddClick('Add new registry')">Add new registry</button>
  </div>
  <div class="govuk-button-group">
    <button type="submit" class="govuk-!-font-size-18 govuk-!-font-weight-bold govuk-button" data-module="govuk-button"
      (click)="onSaveChanges('Save changes')" [disabled]="changedIdpList.length ==0 && !isMfaOptionChanged">Save changes</button>
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
      (click)="onCancel('Cancel')">Cancel</button>
  </div>
</div>